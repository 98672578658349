<template>
    <v-card flat class="fullwidth">
        <v-card-text>
            <v-item-group v-model="selected" multiple mandatory>
                <carousel :per-page="6" autoplay-hover-pause
                          :pagination-enabled="false"
                          :navigation-enabled="true"
                          :per-page-custom="[[768, 4], [1024, 6]]"
                >
                    <slide v-for="item in items" :key="item.id" class="pa-1" style="min-height: 100px;">
                        <v-item v-slot="{ active, toggle }">
                            <v-card flat max-width="150" @click.stop="toggle">
                                <v-card-title class="title-card" v-if="hasCheckbox">
                                    <v-spacer />
                                    <v-btn class="ml-1" elevation="1" small fab>
                                        <v-icon :color="active ? `success` : '#000000'">
                                            {{ active ? 'mdi-sticker-check' : 'mdi-sticker-outline' }}
                                        </v-icon>
                                    </v-btn>
                                </v-card-title>
                                <clazy-load :src="getImage(item)">
                                    <img slot="image" :src="getImage(item)" style="max-width: 100%;">
                                    <div class="preloader" slot="placeholder">
                                        <v-icon large>mdi-spin</v-icon>
                                    </div>
                                </clazy-load>
                            </v-card>
                        </v-item>
                    </slide>
                </carousel>
            </v-item-group>
        </v-card-text>
    </v-card>
</template>

<script>
    import _cloneDeep from "lodash/cloneDeep";
    import {offers_mixin} from "../mixin";

    export default {
        name: "SelectTemplate",
        mixins: [offers_mixin],
        props: {
            attachments: Array,
            hasCheckbox: {type: Boolean, default: true}
        },
        data: () => ({
            items: [],
            selected: [],
        }),
        watch: {
            attachments: {
                handler(val) {
                    this.items = _cloneDeep(val)
                    if (val)
                        this.selected.push(...val.keys())
                },
                deep: true,
                immediate: true
            },
            selected(val) {
                let map = this.items.filter((item, index) => {
                    return val.includes(index)
                })
                this.$emit('input', map)
            }
        },
        methods: {
            emit_action(action) {
                this.$emit(action, this.item)
            }
        }
    }
</script>

<style scoped lang="scss">
    .title-card {
        position: absolute;
        top: 0px;
        width: 100%;
        background: transparent;
    }
</style>