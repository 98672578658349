<template>
  <div class="form-input">
    <v-form ref="form" :disabled="formDisabled">
      <v-card flat>
        <slot name="prepend" />
        <template v-if="structures && structures.length > 0">
          <v-col md="12" v-for="(item, pIndex) in structures" :key="item.id">
            <div v-if="item.type === `checkbox`">
              <label v-if="item.label">
                {{ item.label }} <sup v-if="item.required">*</sup>
              </label>
              <v-checkbox
                dense
                outlined
                hide-details="auto"
                :label="item.text"
                v-model="item.value"
                :disabled="formDisabled"
              />
            </div>
            <div v-else-if="item.type === `radio_group`">
              <label v-if="item.label" class="fw-700 primary--text">
                {{ item.label }} <sup v-if="item.required">*</sup>
              </label>
              <v-radio-group
                :disabled="formDisabled"
                v-model="item.value"
                v-if="item.direction === 'row'"
                row
                :mandatory="item.required"
              >
                <v-radio
                  v-for="(option, i) in item.items"
                  :key="i"
                  :label="option"
                  :value="option"
                />
              </v-radio-group>
              <v-radio-group
                :disabled="formDisabled"
                v-model="item.value"
                v-else
                column
                :mandatory="item.required"
              >
                <v-radio
                  v-for="(option, i) in item.items"
                  :key="i"
                  :label="option"
                  :value="option"
                />
              </v-radio-group>
            </div>
            <div v-else-if="item.type === `checkboxes`">
              <label v-if="item.label" class="fw-700 primary--text">
                {{ item.label }} <sup v-if="item.required">*</sup>
              </label>
              <v-row no-gutters dense v-if="item.direction === 'row'">
                <v-col md="12" class="d-flex flex-wrap">
                  <v-checkbox
                    v-model="item.value"
                    hide-details="auto"
                    class="mr-3 my-1"
                    :disabled="formDisabled"
                    v-for="(option, i) in item.items"
                    :key="i"
                    :label="option"
                    :value="option"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters dense v-else>
                <v-col md="12">
                  <v-checkbox
                    v-model="item.value"
                    hide-details="auto"
                    v-for="(option, i) in item.items"
                    :key="i"
                    class="my-1"
                    :label="option"
                    :value="option"
                    :disabled="formDisabled"
                  />
                </v-col>
              </v-row>
            </div>
            <div v-else-if="item.type === `file_upload`">
              <label v-if="item.label" class="fw-700 primary--text">
                {{ item.label }} <sup v-if="item.required">*</sup>
              </label>
              <v-file-input
                clearable
                clear-icon="mdi-close-circle-outline"
                filled
                outlined
                dense
                :rules="item.required ? requiredRules : []"
                counter
                :multiple="item.multiple"
                :placeholder="item.placeholder"
                hide-details="auto"
                :disabled="formDisabled"
                @change="onchange($event, pIndex, item.multiple)"
                :error="item.error"
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-cloud-question
                      </v-icon>
                    </template>
                    <span>
                      Max file size upload : 10MB<br />
                      Allowed files: image, video, office and compressed files
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 3"
                    color="deep-purple accent-5"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                  <span
                    v-else-if="index === 3"
                    class="overline grey--text text--darken-3 mx-2"
                  >
                    + {{ item.value.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
            </div>
            <div v-else>
              <label v-if="item.label" class="fw-700 primary--text">
                {{ item.label }} <sup v-if="item.required">*</sup>
              </label>
              <component
                :placeholder="item.placeholder"
                :type="item.tag_type"
                v-model="item.value"
                filled
                outlined
                dense
                :rules="item.required ? requiredRules : []"
                hide-details="auto"
                :is="item.tag"
                :rows="item.rows"
                :items="item.items"
                :disabled="formDisabled"
              ></component>
            </div>
          </v-col>
        </template>
        <Empty v-else headline="No form found!" icon="mdi-form-textbox-lock" />
        <slot name="append" />
      </v-card>
    </v-form>
  </div>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import request from "@/services/axios_instance";

export default {
  name: "FormInput",
  props: {
    value: Array,
    forms: Array,
    formDisabled: { type: Boolean, default: false },
  },
  data: () => ({
    structures: [],
    form: false,
    isFromSet: false,
  }),
  watch: {
    forms: {
      handler(val) {
        if (!this.isFromSet) {
          this.structures = val ? _cloneDeep(val) : [];
          this.isFromSet = true;
        }
      },
      deep: true,
      immediate: true,
    },
    value: {
      handler(val) {
        this.structures = _cloneDeep(val);
      },
      deep: true,
      immediate: true,
    },
    structures: {
      handler(val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    alignClass(align) {
      if (align === "right") return "ml-auto";
      else if (align === "center") return "mx-auto";
      else return "mr-auto";
    },
    onchange(files, index, isMultiple) {
      this.structures[index].value = [];
      this.structures[index].error = false;
      let errors = [];
      if (isMultiple) {
        files.forEach((file) => {
          if (file.size <= this.settings.fileSizeMaxUploadLimit) {
            let formData = new FormData();
            formData.append("file", file);
            request.post(`api2/attachments/dropzone`, formData).then(
              ({ data }) => {
                this.structures[index].value = data;
              },
              (error) => {
                this.structures[index].error = true;
                errors.push(error.toString());
              }
            );
          } else {
            errors.push(`${file.name} exceed allowed max filesize limit`);
          }
        });
      } else {
        if (files.size <= this.settings.fileSizeMaxUploadLimit) {
          let formData = new FormData();
          formData.append("file", files);
          request.post(`api2/attachments/dropzone`, formData).then(
            ({ data }) => {
              this.structures[index].value = data;
            },
            (error) => {
              this.structures[index].error = true;
              errors.push(error.toString());
            }
          );
        } else {
          errors.push(`${files.name} exceed allowed max filesize limit`);
        }
      }
      this.$nextTick(() => {
        if (errors.length > 0) {
          this.appSnackbar( errors.join("<br>"), "error");
        }
      });
      this.$emit("files-added", files);
    },
  },
};
</script>

<style scoped>
</style>